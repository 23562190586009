//Importo Componenti react
import React, { useState } from 'react'
import {BrowserRouter as Router} from 'react-router-dom'
import Routes from './Routes'
import { AuthContext } from "./context/auth";



//Importo Style App
import './App.css';

//Importo .Env
require("dotenv").config();


const App = (props) => {

  // Managing auth
  const existingTokens = JSON.parse(localStorage.getItem("tokens"));
  const [authTokens, setAuthTokens] = useState(existingTokens);

  const setTokens = (data) => {
    localStorage.setItem("tokens", JSON.stringify(data));
    setAuthTokens(data);
  }
  // ./Managing auth

  return (
    <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
      <Router>
          <Routes />
      </Router>
    </AuthContext.Provider>
  )
}
export default App
